@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Gotham';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/Gotham-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: bold;
  font-style: normal;
  src: url('../assets/fonts/Gotham-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: normal;
  font-style: italic;
  src: url('../assets/fonts/Gotham-BookItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 600;
  font-style: italic;
  src: url('../assets/fonts/Gotham-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  font-style:  normal;
  src: url('../assets/fonts/Gotham-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  font-style: italic;
  src: url('../assets/fonts/Gotham-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Gotham-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  font-style: italic;
  src: url('../assets/fonts/Gotham-MediumItalic.otf') format('opentype');
}
